.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiDropzoneArea-root {
    border-radius: 15px !important;
    border-color: rgba(170, 78, 155, 0.5) !important;
    width: 250px !important;
    height: 250px !important;
    color: rgba(170, 78, 155, 0.5) !important;
    outline:0;
}
.MuiDropzoneArea-icon {
    color: rgba(170, 78, 155, 0.5) !important;
}
.MuiDropzonePreviewList-imageContainer {
    display: none !important;
}
.Mui-error {
    background-color: white !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: white !important;
    background-color: rgba(170, 78, 155, 1);
}
.ProjectsNameOverviewNames {
    border-bottom-color: #ddd;
    border-bottom-style: dotted;
    border-bottom-width: thin;
    border-right-color: rgba(170, 78, 155, 1);
    border-right-style: solid;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    align-items: flex-end;
    width: 200px;
    max-width: 200px;
    padding-right: 0 !important;
}
.ProjectsNameOverviewNames:first-child {
    padding-top: 40px;
}
.ProjectsNameOverviewTitles {
    border-bottom-color: rgba(170, 78, 155, 1);
    border-bottom-style: solid;
    border-right-color: rgba(170, 78, 155, 1);
    border-right-style: solid;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    align-items: flex-end;
    width: 200px;
    max-width: 200px;
}
.ProjectsNameOverviewValue {
    border-bottom-color: #ddd;
    border-bottom-style: solid;
    border-bottom-width: thin;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    align-items: center;
    width: calc(100%);
    padding-left: 5px;
}
.ProjectsNameOverviewValueTitle {
    border-bottom-color: rgba(170, 78, 155, 1);
    border-bottom-style: solid;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    align-items: flex-end;
    width: 100%;
}
.ProjectsNameOverviewInput {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    font-size: 15px;
}
.ProjectsNameOverviewInput:disabled{
    border: 1px solid white;
    background-color: white;
    color: black;
}
